<template>
  <div class="todo-modal-element">
    <b-modal
      size="lg"
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      hide-footer
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <b-button
            v-if="taskLocal._id"
            size="sm"
            :variant="
              taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'
            "
            @click="
              taskLocal.isCompleted = !taskLocal.isCompleted;
              $emit('complete-task', taskLocal);
            "
          >
            {{ taskLocal.isCompleted ? "Completed" : "Mark Complete" }}
          </b-button>
          <h5 v-else class="mb-0">  {{ $t('add_tasks') }}</h5>
          <div>
            <b-button
              v-show="taskLocal._id"
              size="sm"
              :variant="
                taskLocal.isDeleted ? 'outline-danger' : 'outline-secondary'
              "
              @click="
                $emit('remove-task', taskLocal);
                hide();
              "
            >
              {{ taskLocal.isDeleted ? "Delete" : "Mark as Deleted" }}
              <feather-icon
                v-show="taskLocal._id"
                icon="TrashIcon"
                class="cursor-pointer"
              />
            </b-button>

            <!-- <feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.isImportant }"
              @click="taskLocal.isImportant = !taskLocal.isImportant"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            /> -->
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div class="row">
              <!-- Title -->
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
                class="col-md-6 col-12"
              >
                <b-form-group :label="$t('Title')" label-for="task-title">
                  <b-form-input
                    id="task-title"
                    v-model="taskLocal.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Task Title"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Assignee -->
              <b-form-group 
              :label="$t('Assignee')"
               label-for="assignee"  
              class="col-md-6 col-12"
                >
                <v-select
                  v-model="taskLocal.assignee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="usersOptions"
                  label="name"
                  class="assignee-selector"
                  input-id="assignee"
                  multiple
                >
                  <template #option="{ name }">
                    <span class="ml-50 d-inline-block align-middle">
                      {{ name }}</span
                    >
                  </template>
                </v-select>
              </b-form-group>
            </div>
            <div class="row">
              <!-- due Date -->
              <validation-provider
                #default="validationContext"
                rules="required"
                class="col-md-4 col-12"
              >
                <b-form-group :label="$t('startDate')" label-for="start-date">
                  <flat-pickr
                  v-if="taskLocal.date"
                  id="start-date"
                    v-model="taskLocal.date.startDate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    required
                  />
                    <flat-pickr
                  v-else
                  id="start-date"
                    v-model="taskLocal.startDate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    required
                  />
                  <b-form-invalid-feedback
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="Due Date"
                   class="col-md-4 col-12"
              >
                <b-form-group 
                              :label="$t('EndDate')"

                label-for="due-date">
                  <flat-pickr
                    v-if="taskLocal.date"
                    v-model="taskLocal.date.endDate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                     <flat-pickr
                  v-else
                  id="start-date"
                    v-model="taskLocal.endDate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    required
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!--Tag -->
              <!-- <b-form-group
                 :label="$t('TimeReminder')"
                label-for="vi-reminder"
                   class="col-md-3 col-12"
              >
                <v-select
                  id="vi-reminder"
                  v-model="taskLocal.timereminder"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :selectable="(option) => !option.value.includes('choisir')"
                  :options="time"
                  :reduce="(time) => time.value"
                  label="text"
                />
              </b-form-group> -->
              <b-form-group label="Tag" label-for="tag"         class="col-md-4 col-12"
>
                <v-select
                  v-model="taskLocal.tags"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="tagsOptions"
                  :reduce="(option) => option._id"
                  input-id="tags"
                  label="name"
                  multiple
                />
                <template #option="{ label }">
                  <span class="ml-50 d-inline-block align-middle">
                    {{ label }}</span
                  >
                </template>
              </b-form-group>
            </div>
            <!-- Description -->
            <b-form-group label="Description" label-for="task-description">
              <quill-editor
                id="quil-content"
                v-model="taskLocal.description"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>

            <div
              class="d-flex align-items-center record-section"
              v-if="taskLocal._id"
            >
              <div class="recorded-audio-update display-none">
                <div
                  v-if="taskLocal.file.length == 0"
                  class="d-flex align-items-center"
                >
                 <span class="display-none">   {{ $t('Record_your_voice') }}</span>
                  <vue-record-audio @stream="onStream" @result="onResult" />
                  <div class="recorded-audio">
                    <p v-if="recordings.length == 0">  {{ $t('No_record_yet') }}</p>
                    <div
                      v-for="(record, index) in recordings"
                      :key="index"
                      class="recorded-item"
                    >
                      <div class="audio-container">
                        <audio :src="record.src" controls />
                      </div>
                      <div>
                        <b-button
                          @click="removeRecord(index, recordings)"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-2"
                        >
                          {{ $t('Delete') }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex-center">
                    <div
                      v-for="(record, index) in taskLocal.file"
                      :key="index"
                      class="recorded-item"
                    >
                      <div class="audio-container">
                        <audio :src="record.src" controls />
                      </div>
                    </div>
                    <div>
                      <b-button
                        @click="removeRecordUpdate(taskLocal)"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                      >
                      {{ $t('Delete') }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center record-section" v-else>
               <span class="display-none"> Record your voice:</span>
              <vue-record-audio @stream="onStream" @result="onResult" />
              <div class="recorded-audio">
                <p v-if="recordings.length == 0">No record yet</p>
                <div
                  v-for="(record, index) in recordings"
                  :key="index"
                  class="recorded-item"
                >
                  <div class="audio-container">
                    <audio :src="record.src" controls />
                  </div>
                  <div>
                    <b-button
                      @click="removeRecord(index, recordings)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                    >
                         {{ $t('Delete') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2 justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskLocal._id ? "Update" : "Add " }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                   {{ $t('Reset') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BAvatar,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import { avatarText } from "@core/utils/filter";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import useTaskHandler from "./useTaskHandler";
import axios from "axios";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isTaskHandlerSidebarActive",
    event: "update:is-task-handler-sidebar-active",
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      usersOptions: [],
      tagsOptions: [],
      required,
      email,
      url,
      timeValue: "Nothing",
      recordings: [],

    };
  },
  computed: {
    tagsSelected: {
      get: function () {
        if (this.taskLocal.tags && this.taskLocal.tags.length > 0) {
          var tempArray = [];
          this.taskLocal.tags.forEach((element) => {
            tempArray.push({
              label: element.name,
              value: element._id,
            });
          });
          this.taskLocal.tags = tempArray;
          return this.taskLocal.tags;
        }
      },
      set: function () {
        console.log("here1");
      },
    },
  },
  mounted() {
    this.getUsers();
    this.getTags();
    setTimeout(() => {
      this.getDate();
    }, 500);
    
  },
  methods: {
    endTag(event) {
      // var newArray=Object.values(event);
      // console.log(newArray, event);
      // this.taskLocal.tags=newArray;
    },
    getDate() {
      console.log("this.taskLocal", this.taskLocal);
      // if (this.taskLocal.startDate) {
      //   var newdateFormat = new Date(this.taskLocal.startDate);
      //   this.taskLocal.startDate = newdateFormat.toISOString();
      // }
      // if (this.taskLocal.endDate) {
      //   var newEndDateFormat = new Date(this.taskLocal.endDate);
      //   this.taskLocal.endDate = newEndDateFormat.toISOString();
      // }
    },
    getTags() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/tags?user=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          console.log("tags", response.data.data);
          response.data.data.forEach((element) => {
            this.tagsOptions.push({
              label: element.name,
              name: element.name,
              value: element._id,
              _id: element._id,
            });
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    getUsers() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/users?id=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          console.log("data", response);
          response.data.data.friendList.forEach((element) => {
            this.usersOptions.push({
              name: element.name,
              id: element._id,
            });
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },

    removeRecord(index, recordings) {
      recordings.splice(index, 1);
    },
    removeRecordUpdate(task) {
      task.file = [];
      this.recordings = [];
    },
    onStream(stream) {
      console.log("Got a stream object:", stream);
    },
    onResult(data) {
      console.log("recordings", this.recordings,data);
      this.recordings.push({
        src: window.URL.createObjectURL(data),
      });
      this.taskLocal.file = this.recordings;
    },
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetTaskLocal, props.clearTaskData);

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Write your description",
    };
    var time = [
      {
        value: "5",
        text: "5 minutes",
      },
      {
        value: "15",
        text: "15 minutes",
      },
      {
        value: "30",
        text: "30 minutes",
      },
      {
        value: "45",
        text: "45 minutes",
      },
      {
        value: "60",
        text: "60 minutes",
      },
      {
        value: "90",
        text: "90 minutes",
      },
    ];
    return {
      time,
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      tagOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
.ql-toolbar.ql-snow+.ql-container.ql-snow{
  height: 50px!important;
}
.quill-container{
  height: 50px;
}
.ql-editor{
  height: 50px;
  min-height: 50px;
}

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
.vue-audio-recorder {
  background-color: #9600ff !important;
  width: 34px !important;
  height: 34px !important;
  margin-left: 0% !important;
  margin-right: 9% !important;
}
.vue-audio-recorder,
.vue-video-recorder {
  margin-right: 16px;
}
.record-settings {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.record-section {
  margin-top: 20px !important;
}
.recorded-audio-update {
  // margin: 0 auto;
  width: 100%;
  height: 70px;
  overflow: hidden;
  border: thin solid #eee;
  background-color: #f7f7f7;
  padding: 10px 5px;
  .recorded-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .audio-container {
    display: flex;
    height: 54px;
    margin-right: 16px;
  }
}
.recorded-audio {
  // margin: 0 auto;
  width: 70%;
  height: 70px;
  overflow: hidden;
  border: thin solid #eee;
  background-color: #f7f7f7;
  padding: 10px 5px;
  .recorded-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .audio-container {
    display: flex;
    height: 54px;
    margin-right: 16px;
  }
}
.recorded-video {
  video {
    width: 100%;
    max-height: 400px;
  }
}
.d-flex-center {
  display: flex;
  justify-content: center;
}


</style>
